import React from "react";
import {Trans, useTranslation, I18nextContext} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import {graphql, Link} from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";
import {returnLocaleURL} from "../../../utils/utils";
import Solution from "../../../components/solutions/Solution";

import patApplications from './images/applications/patient-app.jpg';
import staffRooms from './images/applications/staff-locker.jpg';
import medCarts from './images/applications/med-carts.jpg';
import examRooms from './images/applications/exam-room.jpg';

import posterDigilink from "./images/poster-product-healthcare.jpg";
import posterHealthcare from "./images/poster-healthcare.jpg";

import CustomerVideoWithList from "../../../components/solutions/CustomerVideoWithList";
import CustomerVideo from '../../../components/solutions/CustomerVideo';
import ElectronicLockLineup from "../../../components/solutions/ElectronicLockLineup";
import GatsbySlideshow from "../../../components/carousel/gatsby-slideshow/GatsbySlideshow";

const IndexPage = (data) => {

  const {t} = useTranslation();
  const context = React.useContext(I18nextContext);
  let sl = context.language;
  const d = data.data.Slides.edges;
  const images = [];
  for(let i= 0; i<= d.length-1; i++) {
    images.push(d[i].node.childImageSharp);
  }
  const projectAsides = [
    '<span>the netherlands</span><br>' +
    '<h2>Erasmus MC</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '/">Digilock 4G Keypad</a></p>',

    '<span>the netherlands</span><br>' +
    '<h2>Deltares Research Institutes</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock 4G Keypad</a></p>',

    '<span>the netherlands</span><br>' +
    '<h2>St Antonius</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock Versa Standard RFID</a></p>',

    '<span>the netherlands</span><br>' +
    '<h2>VvAA</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock 4G Keypad</a></p>',

    '<span>the netherlands</span><br>' +
    '<h2>Freezlab</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock Sola Keypad</a></p>',

    '<span>switzerland</span><br>' +
    '<h2>Roche Pharmaceuticals</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock Axis RFID</a></p>',

    '<span>switzerland</span><br>' +
    '<h2>Lonza Bioscience</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock Sola Keypad</a></p>',

    '<span>australia</span><br>' +
    '<h2>Maroondah</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock 4G Keypad</a></p>',

    '<span>United States</span><br>' +
    '<h2>Distribution Systems Intl</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock Versa Mini RFID</a></p>'
  ];
  const solutions = [
    {
      img: patApplications,
      label: 'patient_applications',
      alt: 'patient_applications',
      bg: 'light'
    },
    {
      img: staffRooms,
      label: 'staff_locker_rooms',
      alt: 'staff_locker_rooms',
      bg: 'light'
    },
    {
      img: medCarts,
      label: 'medical_carts',
      alt: 'medical_carts',
      bg: 'light'
    },
    {
      img: examRooms,
      label: 'exam_rooms',
      alt: 'exam_rooms',
      bg: 'light'
    }

  ];
  return (
      <Layout>
        <Seo title={t('solutions_healthcare')}
             description={t('des_solutions_healthcare')}/>
        <div className="solutions healthcare">
          <section className="hero --hero-image no-pad">
            <StaticImage
                src="../../../images/industries/hero-healthcare.jpg"
                quality={100}
                layout={'fullWidth'}
                formats={["auto", "webp", "avif"]}
                alt="Healthcare"
            />
            <h1>
              <Trans>
                healthcare
              </Trans>
              <span><Trans>healthcare_subhead</Trans></span>
            </h1>
          </section>
          <section className="customer-video healthcare">
            <CustomerVideoWithList
                title={'customer_video_healthcare_title'}
                list={['customer_video_healthcare_list_l1',
                  'customer_video_healthcare_list_l2',
                  'customer_video_healthcare_list_l3',
                  'customer_video_healthcare_list_l4',
                  'customer_video_healthcare_list_l5',
                  'customer_video_healthcare_list_l6']}
                vidURL={'https://cdn.mediavalet.com/usva/digilock/qr9hU9BzLECLh-UmnWrnKQ/QBYO3t9wOEGNtUvfl0spKQ/Widescreen720p/May%202020%20Digilock%20Healthcare%20V4.mp4'}
                poster={posterDigilink}
            />
          </section>
          <ElectronicLockLineup showPivot={true} showCurve={true} showAspire={true} showVersa={true} showOrbit={true} showCTA={true} label={'healthcare_lineup_label'} />
          <section className="industry-carousel">
            <div className="container">
              <h2><Trans>healthcare_carousel_title</Trans></h2>
              <GatsbySlideshow images={images} overlays={projectAsides} />
            </div>
          </section>
          <br/><br/><br/><br/>
          <section className="customer-video healthcare">
            <CustomerVideo
                poster={posterHealthcare}
                title={'video_healthcare_title'}
                copy={'video_healthcare_copy'}
                vidURL={'https://cdn.mediavalet.com/usva/digilock/BuS8MlhClUiTr6cCYDZkqQ/R2LeM-Kc2UOhl-HNzLowhQ/Widescreen720p/healthcare.mp4'}
            />
          </section>
          <section className="industry-uses">
            <div className="container">
              <h2><Trans>popular_keyless</Trans></h2>
              <Solution items={solutions}/>
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    Slides: allFile(
    filter: {relativeDirectory: {eq: "solutions/healthcare"}}
    sort: {fields: [name], order: ASC}
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
      }
    }
  }
  
  }
`;
